import { ReadStateEnum } from './../pages/AssetsAlarmOverview/types';
import { PageRequest, PageResponse } from './page';
import { fetch } from '@maxtropy/components';
import qs from 'qs';

export interface AlarmLogStatistics {
  // 未恢复数量
  notRecoverNum?: number;
  // 近30日报警数
  alarmNum?: number;
  // 最高级报警数
  topAlarmNum?: number;
  // 高级报警数
  highAlarmNum?: number;
  // 中级报警数
  middleAlarmNum?: number;
  // 上一个30日报警数
  lastAlarmNum?: number;
  // 报警排名
  rankList?: {
    circuitId: number;
    circuitName: string;
    alarmNum: number;
  }[];
}

// 报警统计
export function getAlarmLogStatistics(): Promise<AlarmLogStatistics> {
  return fetch('/api/rule/device/alarm/log/statistics');
}

export enum AlarmLevel {
  HIGHEST = 0,
  HIGH = 1,
  MIDDLE = 2,
  LOW = 3,
  LOWEST = 4,
}

export const AlarmLevelDisplay = {
  [AlarmLevel.HIGHEST]: '最高级',
  [AlarmLevel.HIGH]: '高级',
  [AlarmLevel.MIDDLE]: '中级',
  [AlarmLevel.LOW]: '低级',
  [AlarmLevel.LOWEST]: '最低级',
};

export const AlarmLevelColorDisplay = {
  [AlarmLevel.HIGHEST]: 'error',
  [AlarmLevel.HIGH]: 'warning',
  [AlarmLevel.MIDDLE]: '#427dc3',
  [AlarmLevel.LOW]: 'success',
  [AlarmLevel.LOWEST]: '#a9d134',
};

// 报警记录的报警状态
export enum AlarmState {
  RECOVER = 0,
  UNRECOVERED = 1,
}

export const AlarmStateDisplay = {
  [AlarmState.RECOVER]: '已恢复',
  [AlarmState.UNRECOVERED]: '未恢复',
};

// 异常诊断
export enum AbnormalDiagnosis {
  // 异常
  ABNORMAL = 4,
}

export const AbnormalDiagnosisDisplay = {
  [AbnormalDiagnosis.ABNORMAL]: '异常诊断',
};

// 所属资产
export enum AssetsItems {
  STORAGE = 1,
  PV = 2,
  POWERSTATION = 4,
}
export const assetsItemsDisplay = {
  [AssetsItems.STORAGE]: '储能',
  [AssetsItems.PV]: '光伏',
  [AssetsItems.POWERSTATION]: '充电站',
};

export interface AlarmLogRequest extends PageRequest {
  // 运营单元id
  ouId?: string;
  // 设备编号
  deviceCode?: string;
  // 回路名称
  circuitName?: string;
  // 状态 0/1 恢复/未恢复
  state?: string;
  // 报警等级
  alarmLevels?: string;
  // 报警搜索开始时间
  alarmStartTime?: string;
  // 报警搜索结束时间
  alarmEndTime?: string;
  // 所属配电柜id
  distributionCabinetId?: string;
  sortValues?: any[]; // 与后端约定
}

export interface AlarmLogResponse {
  // 报警id
  id: number;
  // 报警名称
  alarmName?: string;
  // 报警规则编号
  alarmCode?: string;
  // 报警等级
  alarmLevel?: AlarmLevel;
  // 报警时间
  alarmTime?: string;
  // 恢复时间, 未回复为 null
  recoveryTime?: string | null;
  // 设备 id
  deviceId?: string;
  // 设备编号
  deviceCode?: string;
  // 设备名称
  deviceName?: string;
  // 清流告警工单 id
  ruleLogWorkOrderId?: number | null;
  // 回路 id
  circuitId?: number;
  // 回路名称
  circuitName?: string;
  // 配电柜 id
  distributionCabinetId?: number;
  // 配电柜名称
  distributionCabinetName?: string;
  // 运营单元	id
  ouId?: number;
  // 运营单元名称
  ouName?: string;
  // 已读时间, 未读为 null
  readTime?: string | null;
}

// 获取报警记录列表
export function getAlarmLogList(
  query: AlarmLogRequest
): Promise<PageResponse<AlarmLogResponse> & { useSearchAfter: boolean; sortValues: any[] }> {
  return fetch(`/api/rule/device/alarm/log/page?${qs.stringify(query, { indices: false })}`);
}

// 确认报警记录已读
export function readAlarmLog(alarmId: string | number): Promise<boolean> {
  return fetch(`/api/rule/device/alarm/log/read/${alarmId}`, {
    method: 'PUT',
  });
}

export interface AlarmLogDetailResponse extends AlarmLogResponse {
  // 报警规则id
  ruleId?: number;
  // 报警规则编号
  ruleCode?: string;
  // 报警规则名称
  ruleName?: string;
  // 回路编号
  circuitCode?: string;
  // 报警状态 0 恢复, 1 未恢复
  state?: 0 | 1;
  // 网关离线报警编号, 无代表不是网关离线报警
  edgeGatewayNo?: string | null;
}

export function getAlarmLogDetail(alarmId: string): Promise<AlarmLogDetailResponse> {
  return fetch(`/api/rule/device/alarm/log/detail/${alarmId}`);
}

export interface AlarmLogRuleRelatedPropertiesResponse {
  dataPropertyId: number;
  dataPropertyName: string;
  // 报警值 / 触发值
  alarmValue: number;
  recoverValue: number;
  realtimeValue: number;
  // 单位名称
  generalName: string;
  // 是否是录波数据
  isSthf: boolean;
}

// 获取报警记录关联属性以及属性值
export function getAlarmLogRuleRelatedProperties(
  alarmId: string | number
): Promise<AlarmLogRuleRelatedPropertiesResponse[]> {
  return fetch(`/api/rule/device/alarm/log/related/property-value/list/${alarmId}`);
}

export interface RelatedPropertyDetail {
  id: number;
  type: number;
  name: string;
  measurementType: number;
  // 单位名称
  generalName: string;
  enumValue: any;
  used: boolean;
}

// 获取规则关联属性列表, 不存在属性值
export function getRuleRelatedPropertyList(alarmId: string | number): Promise<RelatedPropertyDetail[]> {
  return fetch(`/api/rule/device/alarm/log/related/property/list/${alarmId}`);
}

export interface AlarmLogPointSTFHData {
  ts: number;
  value: number;
}

// 高频录波数据
export function getAlarmLogPointSTFHList(
  alarmId: string | number,
  dataPropertyId: string | number
): Promise<AlarmLogPointSTFHData[]> {
  return fetch(`/api/rule/device/alarm/log/point-sthf-list/${alarmId}?dataPropertyId=${dataPropertyId}`);
}

// 创建工单
export interface WorkOrderRequest {
  // 工单名称
  name: string;
  // 规则报价日志id
  ruleLogId?: string;
}

export function createAlarmWorkOrder(data: WorkOrderRequest) {
  return fetch(`/api/rule/device/alarm/log/workOrder`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

// 查询工单

export interface WorkOrderDetailResponse {
  // 工单名称
  name?: string;
  // 规则名称
  ruleName?: string;
  // 设备名称
  deviceName?: string;
  // 报警等级
  alarmLevel?: AlarmLevel;
  // 报警时间
  alarmTime?: string;
}

export function getAlarmWorkOrderDetail(alarmId: string) {
  return fetch<WorkOrderDetailResponse>(`/api/rule/device/alarm/log/workOrder/${alarmId}`);
}

// 报警分页
export interface AssetsAlarmParams {
  assetId?: number[];
  assetCode?: string[];
  alarmStartTime?: number;
  alarmEndTime?: number;
  deviceCode?: string;
  deviceName?: string;
  assetType?: AssetsItems[];
  level?: AlarmLevel[];
  state?: AlarmState[];
  times?: any[];
  abnormalDiagnosis?: AbnormalDiagnosis[];
  tenantMcids: string[];
  ruleCode?: string;
  ruleName?: string;
}

export interface AssetsAlarmPageReq extends AssetsAlarmParams, PageRequest {
  sortValues: any;
}

export interface AssetsAlarmPageRes {
  alarmId: number;
  alarmTime: number;
  level: AlarmLevel;
  alarmMsg: string;
  state: AlarmState;
  recoveryTime: number;
  deviceId: number;
  sceneType?: number;
  deviceCode: string;
  deviceName: string;
  assetId: number;
  assetCode: string;
  assetName: string;
  ruleId: number;
  ruleName: number;
  ruleCode: string;
  tenantMcid: string;
  ouId: number;
  ouName: string;
  deviceAssets: DeviceAssets[];
  readState: ReadStateEnum;
}
// 报警看板总数据
export interface TotalAlarm {
  topHighAlarmNum: number;
  highAlarmNum: number;
  middleAlarmNum: number;
  lowerAlarmNum: number;
  earlyWarningAlarmNum: number;
}
export interface DeviceAssets {
  assetId: number;
  deviceAssetCode: number;
  deviceAssetType: number;
  deviceAssetName: number;
}

export interface PageAndCount {
  // page: PageResponse<AssetsAlarmPageRes> & {
  //   useSearchAfter: boolean;
  //   sortValues: any[];
  // };
  page: number;
  size: number;
  allAlarmVo: TotalAlarm;
  total: number;
  list: AssetsAlarmPageRes[];
  sortValues: any[];
  useSearchAfter: boolean;
}
export function getPageAndCount(params?: AssetsAlarmPageReq) {
  return fetch<PageAndCount>(`/api/alarm-fault-signboard/alarm-page?${qs.stringify(params, { indices: false })}`);
}
// 查询当前staff所能看见的资产列表
interface AlarmSelectItem {
  assetId: number;
  assetName: string;
  assetCode: string;
}
export const getAssetsAlarmList = (name = '') => {
  return fetch<AlarmSelectItem[]>(`/api/alarm-fault-signboard/alarm-asset?name=${name}`);
};
