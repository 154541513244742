import {
  Form,
  Radio,
  useBreadcrumbRoutes,
  Select,
  Wrapper,
  useSearchParams,
  Paging,
  useCurrent,
  TenantType,
} from '@maxtropy/components';
import { Col, RadioChangeEvent, Row, Statistic } from 'antd';
import styles from './index.module.scss';
import { useEffect, useMemo, useRef, useState } from 'react';
import TableList from './components/TableList';
import CardList from './components/CardList';
import { BsaDashBoardPageReq, ChargeStatus } from '@/api/bsaPVOperationDashBoard';
import { GwhFormat } from './utils';
import { getOuId } from '@/api/ou';
import { useUpdate } from '@maxtropy/components';
import { AppstoreOutlined, UnorderedListOutlined } from '@ant-design/icons';
import {
  apiV2ChannelBsaBsaListPost,
  apiV2ChannelTenantGetTenantListPost,
  apiV2ChannelTenantListPost,
  apiV2DevopsBsaBsaChargeCoutPost,
  apiV2DevopsBsaDashboardPost,
  V2DevopsBsaBsaChargeCoutPostResponse,
} from '@maxtropy/dmes-apis-v2';
import { V2DevopsBsaDashboardPostResponse } from '@maxtropy/dmes-apis-v2/dist/ytt/apis/v2/devopsBsaDashboard';
import { useRequest } from 'ahooks';

export interface EachStatusNumProps {
  all: number; //全部数量
  beCharge: number; //充电数量
  disCharge: number; //放电数量
  standBy: number; //待机数量
}

const formLayout = {
  // labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

export interface BsaDashBoardPageSearchParams extends Omit<BsaDashBoardPageReq, 'pageNum' | 'pageSize'> {}

const BsaOperationDashBoard = () => {
  const isChannel = useCurrent()?.tenant?.tenantType === TenantType.CHANNEL;
  const routesContext = useBreadcrumbRoutes();
  const [type, setType] = useState<string>('card');
  const [processActiveKey, setProcessActiveKey] = useState<string>(ChargeStatus.ALL.toString()); // 输入输出Tab选择
  const [bsaChargeCountData, setBsaChargeCountData] = useState<V2DevopsBsaBsaChargeCoutPostResponse>();
  const [eachStatusNum, setEachStatusNum] = useState<EachStatusNumProps>();

  const [searchForm] = Form.useForm(); // 搜索的表单
  const tenantMcid = Form.useWatch('tenantMcid', searchForm);
  const ouIds = Form.useWatch('ouIds', searchForm);
  const bsaIds = Form.useWatch('bsaIds', searchForm);

  const pagingListInfo = useSearchParams<BsaDashBoardPageSearchParams>(50);
  const pagingCardInfo = useSearchParams<BsaDashBoardPageSearchParams>(12);
  const {
    pageOffset: pageListOffset,
    pageSize: pageListSize,
    setTotalCount: setListTotalCount,
    setPageOffset: setListPageOffset,
  } = pagingListInfo;
  const {
    pageOffset: pageCardOffset,
    pageSize: pageCardSize,
    setTotalCount: setCardTotalCount,
    setPageOffset: setCardPageOffset,
  } = pagingCardInfo;

  useEffect(() => {
    setListPageOffset(1);
    setCardPageOffset(1);
  }, [tenantMcid, ouIds, bsaIds, type]);

  const xx = useMemo(() => {
    return {
      tenantMcid,
      ouIds,
      bsaIds,
      pageListOffset,
      pageListSize,
      pageCardOffset,
      pageCardSize,
      processActiveKey,
      type,
    };
  }, [tenantMcid, ouIds, bsaIds, pageListOffset, pageListSize, pageCardOffset, pageCardSize, processActiveKey, type]);

  const [data, setData] = useState<V2DevopsBsaDashboardPostResponse['list']>([]); // 后端返回数据
  const [isLoading, setIsLoading] = useState<boolean>(false); // 加载状态
  const timer = useRef<number>();
  const [refreshState, refresh] = useUpdate();

  // 储能站充放电统计
  useEffect(() => {
    apiV2DevopsBsaBsaChargeCoutPost({}).then(res => {
      setBsaChargeCountData(res);
    });
  }, [refreshState]);

  // 请求主数据
  useEffect(() => {
    setIsLoading(true);
    apiV2DevopsBsaDashboardPost({
      page: type === 'list' ? pageListOffset : pageCardOffset,
      size: type === 'list' ? pageListSize : pageCardSize,
      chargeState: Number(processActiveKey),
      tenantMcid: tenantMcid,
      ouIds: ouIds,
      bsaIdWithTypeList: bsaList
        ?.filter(item => bsaIds?.includes(item.id))
        .map(item => ({
          bsaId: item.id,
          type: item.type,
        })),
    })
      .then(res => {
        setData(res.list);
        setEachStatusNum({
          all: res.all ?? 0,
          beCharge: res.beCharge ?? 0,
          disCharge: res.disCharge ?? 0,
          standBy: res.standBy ?? 0,
        });
        if (type === 'list') {
          setListTotalCount(res.total ?? 0);
        } else {
          setCardTotalCount(res.total ?? 0);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xx, refreshState]);

  // 每隔1min执行一次请求
  useEffect(() => {
    if (!isLoading) {
      clearTimeout(timer.current);
      timer.current = window.setTimeout(() => {
        refresh();
      }, 60000);
      return () => clearTimeout(timer.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  // 如果是渠道端，请求租户列表
  const { data: tenantList } = useRequest(
    async () => {
      const res = await apiV2ChannelTenantGetTenantListPost();
      return res.list;
    },
    {
      ready: isChannel,
    }
  );

  // 运营单元列表
  const { data: ouList } = useRequest(
    async () => {
      const ouType = (await getOuId())?.filter((i: any) => i.key === 'MICRONET')[0]?.id;
      if (!ouType) return [];
      const res = await apiV2ChannelTenantListPost({
        tenantMcid: tenantMcid,
        type: ouType,
      });
      return res.list;
    },
    {
      refreshDeps: [tenantMcid],
    }
  );

  // 储能站列表
  const { data: bsaList } = useRequest(
    async () => {
      const res = await apiV2ChannelBsaBsaListPost({
        tenantMcid: tenantMcid,
        ouIds: ouIds,
      });
      return res.list;
    },
    {
      refreshDeps: [tenantMcid, ouIds],
    }
  );

  const operations = (
    <div className={styles.typeChangeStyleRadioGroup}>
      <Radio.Group
        // size="small"
        value={type}
        onChange={(e: RadioChangeEvent) => setType(e.target.value)}
      >
        <Radio.Button value="card" style={{ border: type === 'card' ? '1px solid' : '1px solid #ffffff60' }}>
          <AppstoreOutlined />
          <span style={{ marginLeft: 2 }}>卡片模式</span>
        </Radio.Button>
        <Radio.Button value="list" style={{ border: type === 'list' ? '1px solid' : '1px solid #ffffff60' }}>
          <UnorderedListOutlined />
          <span style={{ marginLeft: 2 }}>列表模式</span>
        </Radio.Button>
      </Radio.Group>
    </div>
  );

  const filters = (
    <Form form={searchForm} isOrigin layout="horizontal" labelAlign={'left'} {...formLayout}>
      <Row>
        {isChannel && (
          <Col span={6}>
            <Form.Item name="tenantMcid" label="所属租户">
              <Select
                placeholder="请选择"
                options={tenantList?.map(item => ({
                  label: item.tenantName,
                  value: item.tenantMcid,
                }))}
                allowClear
                onChange={value => {
                  searchForm.setFieldsValue({ tenantMcid: value, ouIds: [], ids: [] });
                }}
              ></Select>
            </Form.Item>
          </Col>
        )}
        <Col span={6}>
          <Form.Item name="ouIds" label="运营单元">
            <Select
              placeholder="请选择"
              options={ouList?.map(item => ({
                value: item.id,
                label: item.name,
              }))}
              showSearch
              optionFilterProp="label"
              mode="multiple"
              maxTagCount="responsive"
              allowClear
              onChange={(value: number[]) => {
                searchForm.setFieldsValue({ ouIds: value, bsaIds: [] });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="bsaIds" label="储能站">
            <Select
              placeholder="请选择"
              options={bsaList?.map(item => ({
                value: item.id,
                label: item.name,
              }))}
              mode="multiple"
              maxTagCount="responsive"
              showSearch
              optionFilterProp="label"
              allowClear
              onChange={(value: number[]) => {
                searchForm.setFieldsValue({ bsaIds: value });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={isChannel ? 6 : 12}>{operations}</Col>
      </Row>
    </Form>
  );

  return (
    <Wrapper routes={routesContext?.routes} className={styles.wrapper}>
      <Row className={styles.DashBoardStyle}>
        <Col className={styles.DashBoardColStyle} span={4}>
          <Statistic
            title={`今日充电电量（${
              bsaChargeCountData?.beChargeByDay ? GwhFormat(bsaChargeCountData?.beChargeByDay).unit : '--'
            }）`}
            value={bsaChargeCountData?.beChargeByDay ? GwhFormat(bsaChargeCountData.beChargeByDay).value : '--'}
          />
        </Col>
        <Col className={styles.DashBoardColStyle} span={4}>
          <Statistic
            title={`今日放电电量（${
              bsaChargeCountData?.disChargeByDay ? GwhFormat(bsaChargeCountData.disChargeByDay).unit : '--'
            }）`}
            value={bsaChargeCountData?.disChargeByDay ? GwhFormat(bsaChargeCountData.disChargeByDay).value : '--'}
          />
        </Col>
        <Col className={styles.DashBoardColStyle} span={4}>
          <Statistic
            title={`本月累计充电（${
              bsaChargeCountData?.beChargeByMonth ? GwhFormat(bsaChargeCountData.beChargeByMonth).unit : '--'
            }）`}
            value={bsaChargeCountData?.beChargeByMonth ? GwhFormat(bsaChargeCountData.beChargeByMonth).value : '--'}
          />
        </Col>
        <Col className={styles.DashBoardColStyle} span={4}>
          <Statistic
            title={`本月累计放电（${
              bsaChargeCountData?.disChargeByMonth ? GwhFormat(bsaChargeCountData.disChargeByMonth).unit : '--'
            }）`}
            value={bsaChargeCountData?.disChargeByMonth ? GwhFormat(bsaChargeCountData.disChargeByMonth).value : '--'}
          />
        </Col>
        <Col className={styles.DashBoardColStyle} span={4}>
          <Statistic
            title={`本年放电量（${
              bsaChargeCountData?.disChargeByYear ? GwhFormat(bsaChargeCountData.disChargeByYear).unit : '--'
            }）`}
            value={bsaChargeCountData?.disChargeByYear ? GwhFormat(bsaChargeCountData.disChargeByYear).value : '--'}
          />
        </Col>
        <Col className={styles.DashBoardColStyle} span={4}>
          <Statistic
            title={`本年充电量（${
              bsaChargeCountData?.beChargeByYear ? GwhFormat(bsaChargeCountData.beChargeByYear).unit : '--'
            }）`}
            value={bsaChargeCountData?.beChargeByYear ? GwhFormat(bsaChargeCountData.beChargeByYear).value : '--'}
          />
        </Col>
      </Row>

      <div className={styles.pageFilter}>{filters}</div>
      {type === 'card' ? (
        <CardList isLoading={isLoading} data={data} />
      ) : (
        <TableList isLoading={isLoading} data={data} />
      )}
      {type === 'list' ? (
        <Paging pagingInfo={pagingListInfo} />
      ) : (
        <Paging pageSizeOptions={['12', '24', '48']} pagingInfo={pagingCardInfo} />
      )}
    </Wrapper>
  );
};

export default BsaOperationDashBoard;
