import { FC } from 'react';
import { V2PvOutlinePvDataStatisticsPostResponse } from '@maxtropy/dmes-apis-v2';
import styles from './index.module.scss';
import { TimeType, TimeTypeLabel } from '.';
import { Col, Row } from 'antd';
import { customFixed } from '@/utils/utils';

interface Props {
  data: V2PvOutlinePvDataStatisticsPostResponse | undefined;
  timeType: TimeType;
}

const ElecStatistics: FC<Props> = props => {
  const { data, timeType } = props;

  const timeTypeLabel = TimeTypeLabel[timeType];

  return (
    <div className={styles.elecStatistics}>
      {timeType === TimeType.NONE ? (
        <div className={styles.cardWrap}>
          <div style={{ flex: 1 }} className={styles.card}>
            <Row>
              <Col span={11}>
                <div style={{ marginBottom: 4 }}>
                  <span className={styles.label}>逆变器发电量:</span>
                  <span>{customFixed(data?.inverterPower)}kWh</span>
                </div>
                <div>
                  <span className={styles.label}>并网点发电量:</span>
                  <span>{customFixed(data?.gridConnectedPower)}kWh</span>
                </div>
              </Col>
              <Col span={1}>
                <div className={styles.splitLine}></div>
              </Col>
              <Col span={11}>
                <div style={{ marginBottom: 4 }}>
                  <span className={styles.label}>上网电量:</span>
                  <span>{customFixed(data?.chargeOnGrid)}kWh</span>
                </div>
                <div>
                  <span className={styles.label}>消纳电量:</span>
                  <span>{customFixed(data?.chargeConsumption)}kWh</span>
                </div>
              </Col>
            </Row>
          </div>
          <div style={{ flex: 1 }} className={styles.card}>
            <Row>
              <Col span={11}>
                <div style={{ marginBottom: 4 }}>
                  <span className={styles.label} style={{ display: 'inline-block', width: '4.5em' }}>
                    总收益:
                  </span>
                  <span>{customFixed(data?.totalIncome)}元</span>
                </div>
                <div>
                  <span className={styles.label} style={{ display: 'inline-block', width: '4.5em' }}>
                    上网收益:
                  </span>
                  <span>{customFixed(data?.incomeOnGrid)}元</span>
                </div>
              </Col>
              <Col span={1}>
                <div className={styles.splitLine}></div>
              </Col>
              <Col span={11}>
                <div style={{ marginBottom: 4 }}>
                  <span className={styles.label}>消纳收益:</span>
                  <span>{customFixed(data?.incomeConsumption)}元</span>
                </div>
                <div>
                  <span className={styles.label}>补贴收益:</span>
                  <span>{customFixed(data?.subsidyIncome)}元</span>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className={styles.cardWrap}>
          <div style={{ flex: '0 0 50%' }} className={styles.card}>
            <Row>
              <Col span={11}>
                <div style={{ marginBottom: 4 }}>
                  <span className={styles.label}>{timeTypeLabel}-并网点发电量:</span>
                  <span>{customFixed(data?.gridConnectedPower)}kWh</span>
                </div>
              </Col>
              <Col span={1}>
                <div className={styles.splitLine}></div>
              </Col>
              <Col span={11}>
                <div style={{ marginBottom: 4 }}>
                  <span className={styles.label}>{timeTypeLabel}-上网电量:</span>
                  <span>{customFixed(data?.chargeOnGrid)}kWh</span>
                </div>
                <div>
                  <span className={styles.label}>{timeTypeLabel}-消纳电量:</span>
                  <span>{customFixed(data?.chargeConsumption)}kWh</span>
                </div>
              </Col>
            </Row>
          </div>
          <div style={{ flex: '0 0 25%' }} className={styles.card}>
            <div style={{ marginBottom: 4 }}>
              <div>
                <span className={styles.label}>{timeTypeLabel}-上网收益:</span>
                <span>{customFixed(data?.incomeOnGrid)}kWh</span>
              </div>
              <div>
                <span className={styles.label}>{timeTypeLabel}-消纳收益:</span>
                <span>{customFixed(data?.incomeConsumption)}kWh</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ElecStatistics;
