import {
  CircuitDataType,
  CircuitProps,
  CircuitType,
  CircuitTypeFormat,
  StatisticsPartition,
  getCircuitList,
} from '@/api/circuit';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, message, Select } from '@maxtropy/components';
import { FormInstance, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { getDistributionCabinetList } from '@/api/distribution';
import { isNil } from 'lodash-es';
import { DefaultValue } from '.';
import { DataType } from '@/api/const';
import circuitBtns from '../../config';
import { circuitBtnsByHistory } from './config';
import { ChartData } from '../..';
import { DatePickerType } from '../DateSwitch';
import { average, sum } from '../../utils';
// import { circuitBtnsByHistory } from './config';

const { RangePicker } = DatePicker;

function circuitTypeOptions() {
  return [
    CircuitType.GRID_BILLING_CIRCUIT,
    CircuitType.INCOMING_CIRCUIT,
    CircuitType.OUTGOING_CIRCUIT,
    CircuitType.BUS_COUPLER_CIRCUIT,
    CircuitType.TRANSFORMER_CIRCUIT,
  ].map(type => ({ label: CircuitTypeFormat[type], value: type }));
}

export interface IHistoryCompare {
  form: FormInstance<any>;
  hisChartData?: {
    dataType: DataType;
    aggrby: StatisticsPartition;
    data: ChartData[];
    btnType: DatePickerType;
    tsStart: number;
    tsEnd: number;
  }[];
  // mediumIndicatorDisplayUnitData?: MediumIndicatorDashboardGetMediumIndicatorDisplayUnitPostResponse;
  // setHisSearchParams: React.Dispatch<React.SetStateAction<any | undefined>>;
  setHisChartData: React.Dispatch<React.SetStateAction<any | undefined>>;
  // historyValues?: any;
  tabData?: CircuitDataType[]; // 指标
  defaultValue?: DefaultValue; // 默认值
  getCapacity?: (val?: number | null) => void;
}
export const colors = ['#0DB6D9', '#63BC7F', '#CE90D1'];

export const todayDefaltFromTo = [dayjs(dayjs(), 'x').startOf('day'), dayjs(dayjs(), 'x').endOf('day')];
export const dayDefaltFromTo = [
  dayjs(dayjs(), 'x').subtract(30, 'days').startOf('day'),
  dayjs(dayjs(), 'x').endOf('day'),
];

const HistoryCompare: FC<IHistoryCompare> = props => {
  const { form, hisChartData, setHisChartData, tabData, defaultValue, getCapacity } = props;
  const [cabinetOptions, setCabinetOptions] = useState<{ label: string; value: number }[]>([]);
  const [circuitOptions, setCircuitOptions] = useState<{ label: string; value: number }[]>([]);
  const [circuitList, setCircuitList] = useState<CircuitProps[]>([]);
  const [pointOptions, setPointOptions] = useState<{ label: string; value: number }[]>([]);

  const circuitType: number | undefined = Form.useWatch('circuitType', form);
  const distributionCabinetId: number | undefined = Form.useWatch('distributionCabinetId', form);
  const circuitId: number | undefined = Form.useWatch('circuitId', form);
  const timeResolution = Form.useWatch('timeResolution', form);
  const point = Form.useWatch('point', form);

  // 获取配电柜
  useEffect(() => {
    getDistributionCabinetList().then(res => {
      let cabinetList = (res ?? []).map(item => ({ value: item.id, label: item.name }));
      setCabinetOptions(cabinetList);
    });
  }, []);

  // 获取回路
  useEffect(() => {
    if (distributionCabinetId && circuitType) {
      setHisChartData(undefined);
      // form.setFieldValue('circuitId', undefined);
      getCircuitList({
        distributionCabinetId: distributionCabinetId,
        type: circuitType,
      }).then(res => {
        let circuitList = res ?? [];
        setCircuitList(circuitList);
        setCircuitOptions(circuitList.map(i => ({ label: i.name, value: i.id })));
      });
    }
  }, [circuitType, distributionCabinetId]);

  // 获取装机容量
  useEffect(() => {
    if (!isNil(circuitId) && circuitList) {
      getCapacity?.(circuitList.find(item => item.id === circuitId)?.capacity);
    }
  }, [circuitId, circuitList]);

  // 获取监控指标
  useEffect(() => {
    setHisChartData(undefined);
    if (circuitOptions && !isNil(circuitId)) {
      let points = tabData?.filter(item => item.name !== '线损').map(item => ({ value: item.id, label: item.name }));
      setPointOptions(points ?? []);
    }
  }, [circuitId, circuitOptions, tabData]);

  useEffect(() => {
    setHisChartData(undefined);
    if (timeResolution === StatisticsPartition.MINUTE_15) {
      form.setFieldsValue({
        timeRangeList: [
          {
            fromAndTo: todayDefaltFromTo,
          },
        ],
      });
    } else {
      form.setFieldsValue({
        timeRangeList: [
          {
            fromAndTo: dayDefaltFromTo,
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeResolution]);

  const disabledDate = (current: Dayjs) => {
    return current && current >= dayjs().endOf('day');
  };

  const addCpmpareTime = (
    add: (defaultValue?: any, insertIndex?: number | undefined) => void,
    filedsLength: number
  ) => {
    if (filedsLength >= 3) {
      message.warning('最多添加3组对比时间');
    } else {
      add();
    }
  };

  useEffect(() => {
    setHisChartData(undefined);
    if (!isNil(point)) {
      form.setFieldValue('timeResolution', circuitBtnsByHistory.find(i => i.id === point)?.defaultTimeResolution);
    }
  }, [point]);

  const totalCount: any[] = useMemo(() => {
    if (hisChartData && hisChartData.length > 0) {
      const currentCapacity = circuitList.find(item => item.id === circuitId)?.capacity;

      if (hisChartData[0].dataType === DataType.PE) {
        const sumCount = hisChartData.map(item => {
          return {
            positive: sum((item.data?.[0]?.data ?? []).map(i => i.value)),
            negative: sum((item.data?.[1]?.data ?? []).map(i => i.value)),
            unit: circuitBtns.find(item => item.id === hisChartData[0].dataType)?.unit,
          };
        });
        return sumCount;
      } else if (hisChartData[0].dataType === DataType.EP) {
        const sumCount = hisChartData.map(item => {
          return {
            positive: sum((item.data[0]?.data ?? []).map(i => i.value)),
            unit: circuitBtns.find(item => item.id === hisChartData[0].dataType)?.unit,
          };
        });
        return sumCount;
      } else if (
        hisChartData[0].dataType === DataType.PDMD ||
        hisChartData[0].dataType === DataType.LOAD_RATE ||
        hisChartData[0].dataType === DataType.COSQ ||
        hisChartData[0].dataType === DataType.P
      ) {
        const avgCount = hisChartData?.map(item => {
          return {
            average:
              hisChartData[0].dataType === DataType.LOAD_RATE
                ? average(
                    item.data[0].data.map(i =>
                      !isNil(currentCapacity) && !isNil(i.value) ? (i.value * 100) / currentCapacity : null
                    )
                  )
                : average(item.data[0].data.map(i => i.value)),
            unit: circuitBtns.find(item => item.id === hisChartData[0].dataType)?.unit,
          };
        });

        return avgCount;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }, [hisChartData]);

  const renderTotal = (name: number) => {
    if (hisChartData?.[0].dataType === DataType.PE) {
      return (
        <>
          <div>
            正向有功电能：
            <span style={{ color: colors[name], fontSize: 16 }}>
              {!isNaN(totalCount[name]?.positive)
                ? totalCount[name]?.positive.toFixed(2) + totalCount[name]?.unit ?? '--'
                : '--'}
            </span>
          </div>
          <div>
            反向有功电能：
            <span style={{ color: colors[name], fontSize: 16 }}>
              {!isNaN(totalCount[name]?.negative)
                ? totalCount[name]?.negative.toFixed(2) + totalCount[name]?.unit ?? '--'
                : '--'}
            </span>
          </div>
        </>
      );
    } else if (hisChartData?.[0].dataType === DataType.EP) {
      return (
        <>
          <div>
            总电量：
            <span style={{ color: colors[name], fontSize: 16 }}>
              {!isNaN(totalCount[name]?.positive)
                ? totalCount[name]?.positive.toFixed(2) + totalCount[name]?.unit ?? '--'
                : '--'}
            </span>
          </div>
        </>
      );
    } else if (
      hisChartData?.[0].dataType === DataType.PDMD ||
      hisChartData?.[0].dataType === DataType.LOAD_RATE ||
      hisChartData?.[0].dataType === DataType.COSQ ||
      hisChartData?.[0].dataType === DataType.P
    ) {
      return (
        <>
          <div>
            均值：
            <span style={{ color: colors[name], fontSize: 16 }}>
              {!isNaN(totalCount[name]?.average)
                ? totalCount[name]?.average.toFixed(2) + totalCount[name]?.unit ?? '--'
                : '--'}
            </span>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const onCircuitTypeChange = () => {
    form.setFieldsValue({
      distributionCabinetId: undefined,
      circuitId: undefined,
      point: undefined,
    });
  };

  const onCabinetChange = () => {
    form.setFieldsValue({
      circuitId: undefined,
      point: undefined,
    });
  };

  const onCircuitChange = () => {
    form.setFieldsValue({
      point: undefined,
    });
  };

  return (
    <>
      <div className={styles.compareSection}>
        <Form.Item
          name="circuitType"
          label="回路类型"
          rules={[{ required: true, message: '请选择回路类型' }]}
          initialValue={defaultValue?.type}
        >
          <Select
            placeholder="请选择回路类型"
            style={{ width: '100%' }}
            // value={circuitTypeValue}
            options={circuitTypeOptions()}
            onChange={onCircuitTypeChange}
            allowClear
            onClear={() => setHisChartData(undefined)}
          />
        </Form.Item>
        <Form.Item
          name="distributionCabinetId"
          label="配电柜"
          rules={[{ required: true, message: '请选择配电柜' }]}
          initialValue={defaultValue?.cabinetId}
        >
          <Select
            placeholder="请选择配电柜"
            style={{ width: '100%' }}
            allowClear
            onClear={() => {
              form.setFieldValue('circuitId', undefined);
              setHisChartData(undefined);
            }}
            // value={cabinetValue}
            options={cabinetOptions}
            onChange={onCabinetChange}
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item
          name="circuitId"
          label="回路"
          rules={[{ required: true, message: '请选择回路' }]}
          initialValue={defaultValue?.circuitId}
        >
          <Select
            placeholder="请选择回路"
            style={{ width: '100%' }}
            // value={circuitValue}
            onChange={onCircuitChange}
            options={circuitOptions}
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item
          name="point"
          label="监控指标"
          rules={[{ required: true, message: '请选择监控指标' }]}
          initialValue={defaultValue?.dataType}
        >
          <Select
            placeholder="请选择监控指标"
            showSearch
            optionFilterProp="label"
            options={pointOptions}
            style={{ width: '100%' }}
            // value={pointValue}
            // onChange={onPointChange}
          />
        </Form.Item>
        <Form.Item name="timeResolution" label="颗粒度" rules={[{ required: true, message: '请选择颗粒度' }]}>
          {circuitBtnsByHistory.find(i => i.id === point)?.render ?? (
            <span style={{ color: 'red' }}>请先选择监控指标</span>
          )}
        </Form.Item>

        <Form.List name="timeRangeList">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div
                  key={key}
                  style={{
                    marginBottom: 8,
                    padding: 6,
                    borderRadius: 2,
                    backgroundColor: '#ffffff10',
                    borderLeft: `3px solid ${colors[name]}`,
                  }}
                >
                  <Space
                    style={{
                      display: 'flex',
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      label={`时间${name + 1}`}
                      name={[name, 'fromAndTo']}
                      style={{ marginBottom: 12 }}
                      rules={[
                        { required: true, message: '请选择对比时间' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (value) {
                              if (getFieldValue('timeResolution')) {
                                const timeResolution = getFieldValue('timeResolution');
                                const [beginDate, endDate] = value;
                                const diff = dayjs(endDate).diff(dayjs(beginDate), 'day');
                                const mostDays = timeResolution === StatisticsPartition.DAY ? 31 : 1;
                                if (diff >= mostDays) {
                                  return Promise.reject(new Error(`最大上限为${mostDays}天`));
                                }
                              }
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <RangePicker disabledDate={disabledDate} allowClear={false} />
                    </Form.Item>

                    <MinusCircleOutlined
                      style={{ color: '#4A90E2', cursor: 'pointer' }}
                      onClick={() => {
                        if (fields.length === 1) {
                          message.warning('至少保留一组对比时间');
                        } else {
                          remove(name);
                        }
                      }}
                    />
                  </Space>
                  {renderTotal(name)}
                </div>
              ))}
              <Form.Item noStyle>
                <Button
                  style={{ width: '100%' }}
                  type="dashed"
                  onClick={() => addCpmpareTime(add, fields.length)}
                  block
                  icon={<PlusOutlined />}
                >
                  新增对比时间
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
    </>
  );
};

export default HistoryCompare;
