import { useEffect, useState } from 'react';
import { isNil } from 'lodash-es';

export const useQuery = <T>(action: () => Promise<T | undefined>) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<T | undefined>(undefined);
  useEffect(() => {
    setIsLoading(true);
    action()
      .then(setData)
      .finally(() => setIsLoading(false));
  }, [action]);
  return { isLoading, data };
};

export const customFixed = (value: number | string | null | undefined, fixed = 2) => {
  if (isNil(value)) return '--';
  if (isNaN(Number(value))) return '--';
  return Number(Number(value).toFixed(fixed));
};
