import React, { forwardRef, Ref, useEffect, useImperativeHandle, useReducer, useState } from 'react';
import { isNil } from 'lodash-es';
import { DataType, SelectCircuit, SelectOptionProp } from '../../../../api/const';
import {
  CircuitDataType,
  CircuitProps,
  CircuitType,
  CircuitTypeFormat,
  getCircuitList,
  StatisticsPartition,
} from '../../../../api/circuit';
import { DefaultValue } from './index';
import styles from './index.module.scss';
import { getDistributionCabinetList } from '@/api/distribution';
import circuitBtns from '../../config';
import { ChartData } from '../..';
import { DatePickerType } from '../DateSwitch';
import { Select } from '@maxtropy/components';

export interface CustomSelectProps {
  onChange: (circuit?: SelectCircuit) => void;
  defaultValue?: DefaultValue;
  isReset?: boolean;
  circuits?: Array<SelectCircuit | undefined>;
  index: number;
  dataType?: DataType;
  tabData?: CircuitDataType[];
  setChartData: React.Dispatch<
    React.SetStateAction<
      | {
          dataType: DataType;
          aggrby: StatisticsPartition;
          data: ChartData[];
          btnType: DatePickerType;
        }[]
      | undefined
    >
  >;
}

export interface CustomSelectRef {
  reset?: () => void;
}

const NO_CABINET_ID = -1;

function circuitTypeOptions() {
  return [
    CircuitType.GRID_BILLING_CIRCUIT,
    CircuitType.INCOMING_CIRCUIT,
    CircuitType.OUTGOING_CIRCUIT,
    CircuitType.BUS_COUPLER_CIRCUIT,
    CircuitType.TRANSFORMER_CIRCUIT,
  ].map(type => ({ label: CircuitTypeFormat[type], value: type }));
}

const CustomSelect = forwardRef((props: CustomSelectProps, ref: Ref<CustomSelectRef>) => {
  const { onChange, defaultValue, isReset, index, circuits, tabData, setChartData } = props;

  const [xx, forceUpdate] = useReducer(x => x + 1, 0);

  const [cabinetOptions, setCabinetOptions] = useState<SelectOptionProp[]>([]);
  const [circuitOptions, setCircuitOptions] = useState<CircuitProps[]>([]);
  const [pointOptions, setPointOptions] = useState<SelectOptionProp[]>([]);

  const [circuitTypeValue, setCabinetTypeValue] = useState<number | undefined>(defaultValue?.type);
  const [cabinetValue, setCabinetValue] = useState<number | undefined>(defaultValue?.cabinetId);
  const [circuitValue, setCircuitValue] = useState<number | undefined>();
  const [pointValue, setPointValue] = useState<DataType | undefined>();

  // 获取配电柜
  useEffect(() => {
    getDistributionCabinetList().then(res => {
      let cabinetList = (res ?? []).map(item => ({ id: item.id, name: item.name }));
      let cabinet = cabinetList?.find(k => k.id === defaultValue?.cabinetId);
      if (cabinet) {
        setCabinetValue(cabinet?.id);
      } else {
        cabinetList.push({ name: '无', id: defaultValue?.cabinetId ?? NO_CABINET_ID });
        setCabinetValue(defaultValue?.cabinetId ?? NO_CABINET_ID);
      }
      setCabinetOptions(cabinetList);
    });
  }, [defaultValue, xx]);

  // 获取回路
  useEffect(() => {
    getCircuitList({
      distributionCabinetId: cabinetValue === NO_CABINET_ID ? undefined : cabinetValue,
      type: circuitTypeValue,
    }).then(res => {
      let circuitList = res ?? [];
      setCircuitOptions(circuitList);
    });
  }, [circuitTypeValue, cabinetValue]);

  // 对比回路默认选中当前回路和监控指标
  useEffect(() => {
    if (index === 0) {
      setCircuitValue(defaultValue?.circuitId);
      setPointValue(defaultValue?.dataType);
      if (circuitOptions) {
        const target = circuitOptions?.find(item => item.id === circuitValue);
        onChange({ ...target!, dataType: defaultValue?.dataType });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, index, xx, circuitOptions]);

  // 获取监控指标
  useEffect(() => {
    if (index === 0 && circuitOptions && !isNil(circuitValue)) {
      let points = tabData?.filter(item => item.name !== '线损').map(item => ({ id: item.id, name: item.name }));
      setPointOptions(points ?? []);
    }
  }, [circuitValue, circuitOptions, index, tabData, xx]);

  useEffect(() => {
    if (index === 1) {
      const dataType = circuits?.[0]?.dataType;
      if (dataType) {
        const options = [dataType, ...circuitBtns.find(item => item.id === dataType)!.comparable].map(item => ({
          id: item,
          name: circuitBtns.find(o => o.id === item)!.btnName,
        }));
        setPointOptions(options);
        if (pointValue && !options.find(item => item.id === pointValue)) {
          setPointValue(undefined);
          const target = circuitOptions?.find(item => item.id === circuitValue);
          onChange({ ...target!, dataType: undefined });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circuits, index, xx]);

  useEffect(() => {
    if (isReset) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReset]);

  const reset = () => {
    forceUpdate();
    setCabinetTypeValue(defaultValue?.type);
    setCabinetValue(defaultValue?.cabinetId);
    if (index === 0) {
      setCircuitValue(defaultValue?.circuitId);
      setPointValue(defaultValue?.dataType);
    } else {
      setCircuitValue(undefined);
      setPointValue(undefined);
    }
  };

  // 选择回路类型
  const onCircuitTypeChange = (value: number) => {
    setCabinetTypeValue(value);
    // 向下置空 同时清空图表
    setCabinetValue(undefined);
    setCircuitValue(undefined);
    setPointValue(undefined);
    onChange();
    setChartData(undefined);
  };
  // 选择配电柜
  const onCabinetChange = (value: number) => {
    setCabinetValue(value);
    // 向下置空 同时清空图表
    setCircuitValue(undefined);
    setPointValue(undefined);
    onChange();
    setChartData(undefined);
  };

  // 选择回路
  const onCircuitChange = (value: number) => {
    setPointValue(undefined);
    const target = circuitOptions?.find(item => item.id === value);
    // onChange({ ...target!, dataType: pointValue });
    onChange({ ...target!, dataType: undefined });
    setCircuitValue(value);

    // 反显回路类型
    let circuit = circuitOptions.find(item => item.id === value);
    setCabinetTypeValue(circuit?.type);
    // 反显配电柜
    let cabinetId = circuit?.distributionCabinetId ?? NO_CABINET_ID;
    if (!cabinetOptions.some(k => k.id === cabinetId)) {
      setCabinetOptions([...cabinetOptions, { name: circuit?.distributionCabinetName ?? '无', id: cabinetId }]);
    }
    setCabinetValue(cabinetId);
    setChartData(undefined);
  };

  // 选择监控指标
  const onPointChange = (value: number) => {
    setPointValue(value);
    const target = circuitOptions?.find(item => item.id === circuitValue);
    console.log('target', target);
    onChange({ ...target!, dataType: value });
    setChartData(undefined);
  };

  useImperativeHandle(ref, () => ({ reset }));

  return (
    <div className={styles.customSelect}>
      <div className={styles.row}>
        <span className={styles.label}>回路类型</span>
        <Select
          placeholder="请选择回路类型"
          style={{ width: 180 }}
          value={circuitTypeValue}
          options={circuitTypeOptions()}
          onChange={onCircuitTypeChange}
          allowClear
          onClear={() => setChartData(undefined)}
        ></Select>
      </div>

      <div className={styles.row}>
        <span className={styles.label}>配电柜</span>
        <Select
          placeholder="请选择配电柜"
          style={{ width: 180 }}
          allowClear
          onClear={() => setChartData(undefined)}
          value={cabinetValue}
          options={cabinetOptions}
          onChange={onCabinetChange}
          fieldNames={{ label: 'name', value: 'id' }}
          showSearch
          optionFilterProp="name"
          filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
        ></Select>
      </div>

      <div className={styles.row}>
        <span className={styles.label}>回路</span>
        <Select
          placeholder="请选择回路"
          style={{ width: 180 }}
          value={circuitValue}
          onChange={onCircuitChange}
          options={circuitOptions}
          fieldNames={{ label: 'name', value: 'id' }}
          showSearch
          optionFilterProp="name"
          filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
        ></Select>
      </div>

      <div className={styles.row}>
        <span className={styles.label}>监控指标</span>
        <Select
          placeholder="请选择监控指标"
          fieldNames={{ label: 'name', value: 'id' }}
          options={pointOptions}
          style={{ width: 180 }}
          value={pointValue}
          onChange={onPointChange}
        ></Select>
      </div>
    </div>
  );
});

export default CustomSelect;
