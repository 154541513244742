import { deleteCircuitLoss } from '@/api/lineLoss';
import {
  Form,
  PopConfirm,
  Select,
  EllipsisSpan,
  Input,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  Modal,
  Wrapper,
  CustomFilter,
  Button,
  Tag,
} from '@maxtropy/components';

import { Upload, Space, App } from 'antd';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import dayjs from 'dayjs';
import { extname } from 'path';
import React, { Key, useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
  CircuitPageProps,
  CircuitType,
  CircuitTypeFormat,
  downLoadModuleForMonitoring,
  importMonitoring,
  reCalcCircuitElec,
} from '../../api/circuit';
import { getDistributionCabinetList } from '../../api/distribution';
import { fetchOuList, getOuId } from '../../api/ou';
import { PermissionsType } from '../../common/permissionsConst';
import { useHasPermission } from '../../utils/hooks';
import LogListModal from './components/LogListModal';
import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import ReCalcModal from './components/ReCalcModal';
import LightHeightOption from './components/LightHeightOption';
import { useRequest } from 'ahooks';
import { apiV2CircuitPagePost } from '@maxtropy/dmes-apis-v2';

// const cx = classnames.bind(styles);
const { Option } = Select;

const columns = [
  {
    title: '回路编号',
    dataIndex: 'code',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '回路名称',
    dataIndex: 'name',
    width: 180,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '类型',
    dataIndex: 'type',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: CircuitType) => <EllipsisSpan value={CircuitTypeFormat[v]} />,
  },
  {
    title: '所属运营单元',
    dataIndex: 'ouName',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属配电柜',
    dataIndex: 'distributionCabinetName',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'updateByStaffName',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface SearchParamsProps {
  codeOrName?: string;
  type?: CircuitType;
  distributionCabinetIds?: number[];
  ouIds?: number[];
}

function circuitTypeOptions() {
  const type: Array<{ label: string; value: CircuitType }> = [
    {
      label: CircuitTypeFormat[CircuitType.GRID_BILLING_CIRCUIT],
      value: CircuitType.GRID_BILLING_CIRCUIT,
    },
    {
      label: CircuitTypeFormat[CircuitType.INCOMING_CIRCUIT],
      value: CircuitType.INCOMING_CIRCUIT,
    },
    {
      label: CircuitTypeFormat[CircuitType.OUTGOING_CIRCUIT],
      value: CircuitType.OUTGOING_CIRCUIT,
    },
    {
      label: CircuitTypeFormat[CircuitType.BUS_COUPLER_CIRCUIT],
      value: CircuitType.BUS_COUPLER_CIRCUIT,
    },
    {
      label: CircuitTypeFormat[CircuitType.TRANSFORMER_CIRCUIT],
      value: CircuitType.TRANSFORMER_CIRCUIT,
    },
  ];
  return type.map(i => ({ label: i.label, value: i.value }));
}

const fileSize = 50 * 1024 * 1024;

const CircuitManage: React.FC = () => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParamsProps>();
  // 操作日志列表弹框显示/隐藏
  const [logVisible, setLogVisible] = useState(false);
  const routesContext = useBreadcrumbRoutes();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [timeShareRecalcBtnVisible, setTimeShareRecalcBtnVisible] = useState<boolean>(true); // 分时重计算按钮显示与隐藏
  const [recalcBtnVisible, setRecalcBtnVisible] = useState<boolean>(false); // 重计算与取消按钮
  const [reCalcModalVisible, setReCalcModalVisible] = useState<boolean>(false); // 重计算选择时间弹窗

  const [distributionListOptions, setDistributionListOptions] = useState<{ label?: any; value?: number }[]>([]);
  const [ouOptions, setOuOptions] = useState<{ label?: any; value?: number }[]>([]);
  const distributionCabinetIds: number[] = Form.useWatch('distributionCabinetIds', form);
  const ouIds: number[] = Form.useWatch('ouIds', form);

  const { message } = App.useApp();
  // const { data, isLoading, refetch } = useQuery(
  //   ['circuitPage', pageOffset, pageSize, searchParams, setTotalCount],
  //   async () => {
  //     const res = await getCircuitPage({ ...searchParams, page: pageOffset, size: pageSize });
  //     if (!res) return [];
  //     setTotalCount(res.total);
  //     return res.list;
  //   }
  // );
  const { data, loading: isLoading } = useRequest(
    () =>
      apiV2CircuitPagePost({ ...searchParams, page: pageOffset, size: pageSize }).then(res => {
        if (!res) return [];
        setTotalCount(res.total ?? 0);
        return res.list;
      }),
    {
      refreshDeps: [pageOffset, pageSize, searchParams],
    }
  );

  const { data: ouList } = useQuery('ouList', async () => {
    const ouType = (await getOuId())?.filter(i => i.key === 'MICRONET')[0]?.id;
    if (!ouType) return [];
    return fetchOuList(ouType);
  });

  const { data: distributionList } = useQuery('distributionList', () => getDistributionCabinetList());

  const hasToPoPermission = useHasPermission(PermissionsType.B_CIRCUITTOPOLOGY);

  // 添加/编辑, 移除线损配置
  const hasLineLossOpPermission = useHasPermission(PermissionsType.B_LINE_LOSS_CONFIG_EDIT_ADD);
  const hasLineLossRemovePermission = useHasPermission(PermissionsType.B_LINE_LOSS_CONFIG_REMOVE);

  const hasCircuitReCalcPermission = useHasPermission(PermissionsType.B_CIRCUITRERUNELECTRICITYSTATICS);

  // 移除线损配置
  const removeLineLossConfig = (id: number) => {
    deleteCircuitLoss(id).then(res => {
      if (res) {
        queryClient.invalidateQueries('circuitPage');
      }
    });
  };
  const actionColumn = {
    title: '操作',
    width: 300,
    fixed: 'right' as const,
    render: (v: CircuitPageProps) => (
      <Space size={16}>
        <Button
          type="link"
          onClick={() => {
            window.open(`/dmes/circuit/circuit/detail/${v.id}`, '_self');
          }}
        >
          查看
        </Button>
        {hasLineLossOpPermission && (
          <Button
            type="link"
            onClick={() => {
              window.open(
                !v.hasLineLossConfig
                  ? `/dmes/circuit/circuit/lineLoss/${v.id}`
                  : `/dmes/circuit/circuit/lineLoss/${v.id}/edit`,
                '_self'
              );
            }}
          >
            {!v.hasLineLossConfig ? '添加线损配置' : '编辑线损配置'}
          </Button>
        )}
        {hasLineLossRemovePermission && (
          <PopConfirm
            title={
              <>
                <div>确定移除?</div>
                <div className={styles.line_red}>移除线损配置后, 历史线损数据将不可查看,</div>
                <div>您还要继续吗?</div>
              </>
            }
            onConfirm={() => {
              removeLineLossConfig(v.id);
            }}
            disabled={!v.hasLineLossConfig}
            okText="确定"
            cancelText="取消"
          >
            <Button type="link" disabled={!v.hasLineLossConfig}>
              移除线损配置
            </Button>
          </PopConfirm>
        )}
        <Button
          type="link"
          onClick={() => {
            window.open(`/dmes/circuit/circuit/configure/${v.id}`, '_self');
          }}
        >
          配置详情
        </Button>
      </Space>
    ),
  };

  const onChange = (uploadFile: UploadChangeParam<UploadFile>) => {
    const { file } = uploadFile;
    if (file.status === 'error') {
      Modal.warning({
        content: file.response.errorMessage || '未知错误！ 请联系管理员。',
      });
    }
    if (file.status === 'done') {
      queryClient.invalidateQueries('circuitPage');
    }
  };

  const beforeUpload = (file: RcFile) => {
    const { name } = file;
    const extName = extname(name);
    const limitFileSize = file.size <= fileSize;
    const limitFileType = extName === '.xlsx' || extName === '.xls';
    if (!limitFileSize) {
      Modal.warning({
        content: `上传文件的大小不得超过50M`,
      });
      return false;
    }
    if (!limitFileType) {
      Modal.warning({
        content: `文件格式错误！仅支持.xls、.xlsx`,
      });
      return false;
    }
    return true;
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    onChange: (rowKeys: Key[]) => {
      setSelectedRows(rowKeys as number[]);
    },
    selectedRowKeys: selectedRows,
  };

  const onTimeShareRecalc = () => {
    // 调用接口校验是否有正在重计算的
    reCalcCircuitElec().then(_ => {
      setTimeShareRecalcBtnVisible(false);
      setRecalcBtnVisible(true);
    });
  };

  const onRecalc = useCallback(() => {
    if (selectedRows && selectedRows.length) {
      // 先弹窗选择时间
      setReCalcModalVisible(true);
    } else {
      message.error('请选择回路！');
    }
  }, [selectedRows]);

  // 分时重计算选择日期
  const onFinish = (values: any) => {
    reCalcCircuitElec({
      circuitIds: selectedRows,
      from: dayjs(values.timeShareCalcTimes[0]).startOf('day').valueOf(),
      to: dayjs(values.timeShareCalcTimes[1]).endOf('day').valueOf(),
    }).then(_ => {
      setTimeShareRecalcBtnVisible(true);
      setRecalcBtnVisible(false);
      setReCalcModalVisible(false);
      setSelectedRows([]);
    });
  };

  useEffect(() => {
    if (distributionList && distributionList.length !== 0) {
      setDistributionListOptions(distributionList.map(i => ({ label: i.name, value: i.id })));
    }
  }, [distributionList]);

  useEffect(() => {
    if (ouList && ouList.length !== 0) {
      setOuOptions(ouList.map(i => ({ label: i.name, value: i.id })));
    }
  }, [ouList]);

  const [distributionCabinetSearchValue, setDistributionCabinetSearchValue] = useState<string>();
  const [ouSearchValue, setOuSearchValue] = useState<string>();

  const onDistributionCabinetSearch = (value?: string) => {
    setDistributionCabinetSearchValue(value);
  };

  const onOuSearch = (value?: string) => {
    setOuSearchValue(value);
  };

  useEffect(() => {
    if (distributionCabinetIds && distributionCabinetIds.length === 0) {
      setDistributionCabinetSearchValue('');
    }
  }, [distributionCabinetIds]);

  useEffect(() => {
    if (ouIds && ouIds.length === 0) {
      setOuSearchValue('');
    }
  }, [ouIds]);

  const filters = (
    <CustomFilter
      form={form}
      onFinish={(values: any) => {
        setSearchParams(values);
        setPageOffset(1);
        setOuSearchValue('');
        setDistributionCabinetSearchValue('');
        // refetch();
      }}
      onReset={() => {
        setSearchParams(undefined);
        setPageOffset(1);
        setOuSearchValue('');
        setDistributionCabinetSearchValue('');
        // refetch();
      }}
    >
      <Form.Item name="codeOrName" label="编号/名称">
        <Input placeholder={'请输入编号或名称查询'} />
      </Form.Item>
      <Form.Item name="type" label="回路类型">
        <Select placeholder="请选择" options={circuitTypeOptions()} />
      </Form.Item>
      <Form.Item name="distributionCabinetIds" label="所属配电柜">
        <Select
          style={{ width: '100%' }}
          showSearch
          allowClear
          placeholder="请选择"
          onClear={() => setDistributionCabinetSearchValue('')}
          tagRender={(props: any) => (
            <Tag closable={props.closable} onClose={props.onClose}>
              {props?.label?.props?.text}
            </Tag>
          )}
          mode="multiple"
          onSearch={onDistributionCabinetSearch}
          filterOption={(input, option: any) =>
            (option?.children && option?.children?.props?.text.toLowerCase().indexOf(input.toLowerCase())) >= 0
          }
        >
          {(distributionListOptions ?? []).map((option, index) => (
            <Option key={`${option.value}+${index}`} value={option.value}>
              <LightHeightOption text={option.label} filterTxt={distributionCabinetSearchValue} />
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="ouIds" label="所属运营单元">
        <Select
          style={{ width: '100%' }}
          showSearch
          allowClear
          placeholder="请选择"
          onClear={() => setOuSearchValue('')}
          tagRender={(props: any) => (
            <Tag closable={props.closable} onClose={props.onClose}>
              {props?.label?.props?.text}
            </Tag>
          )}
          mode="multiple"
          onSearch={onOuSearch}
          filterOption={(input, option: any) =>
            (option?.children && option?.children?.props?.text.toLowerCase().indexOf(input.toLowerCase())) >= 0
          }
        >
          {(ouOptions ?? []).map((option, index) => (
            <Option key={`${option.value}+${index}`} value={option.value}>
              <LightHeightOption text={option.label} filterTxt={ouSearchValue} />
            </Option>
          ))}
        </Select>
      </Form.Item>
    </CustomFilter>
  );

  return (
    <>
      <Wrapper routes={routesContext?.routes ?? []} filters={filters}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 10,
          }}
        >
          <Space size={8}>
            {!recalcBtnVisible && (
              <>
                <Button type="primary" onClick={downLoadModuleForMonitoring}>
                  <div className={styles.box_fixW}>配置回路模板下载</div>
                </Button>
                <Upload
                  accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                  action={importMonitoring()}
                  onChange={onChange}
                  beforeUpload={beforeUpload}
                  showUploadList={false}
                >
                  <Button type="primary">
                    <div className={styles.box_fixW}>批量配置回路</div>
                  </Button>
                </Upload>

                <Button type="primary" onClick={() => setLogVisible(true)}>
                  批量操作日志
                </Button>
              </>
            )}

            {timeShareRecalcBtnVisible && hasCircuitReCalcPermission && (
              <Button type="primary" onClick={onTimeShareRecalc}>
                分时重计算
              </Button>
            )}
            {recalcBtnVisible && (
              <>
                <Button type="primary" onClick={onRecalc}>
                  重计算
                </Button>
                <Button
                  onClick={() => {
                    setTimeShareRecalcBtnVisible(true);
                    setRecalcBtnVisible(false);
                    setSelectedRows([]);
                  }}
                >
                  取消
                </Button>
                <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginLeft: 6 }} />
                请选择回路后点击【重计算】按钮，已选择<span style={{ color: 'red' }}>{selectedRows.length}</span>回路
              </>
            )}
          </Space>
          {hasToPoPermission && (
            <Button type={'primary'} onClick={() => window.open(`/dmes/circuit/topologicalGraph`, '_blank')}>
              回路拓扑图
            </Button>
          )}
        </div>

        <Table
          sticky
          rowKey="id"
          scroll={{ x: 1500 }}
          columns={[...columns, actionColumn]}
          dataSource={data}
          loading={isLoading}
          rowSelection={
            !timeShareRecalcBtnVisible
              ? {
                  type: 'checkbox',
                  ...rowSelection,
                }
              : undefined
          }
        />
        <Paging pagingInfo={pagingInfo} />
      </Wrapper>

      {/* 操作日志列表弹窗 */}
      <LogListModal
        visible={logVisible}
        setVisible={(visible: boolean) => {
          setLogVisible(visible);
        }}
      />
      {/* 重计算选择日期弹窗 */}
      <ReCalcModal
        onFinish={onFinish}
        visible={reCalcModalVisible}
        setVisible={(visible: boolean) => {
          setReCalcModalVisible(visible);
        }}
      />
    </>
  );
};

export default CircuitManage;
