import { FC, useMemo } from 'react';
import { V2PvOutlinePvDataStatisticsPostResponse } from '@maxtropy/dmes-apis-v2';
import { Granularity, PowerTab, TimeType, TimeTypeLabel } from './index';
import { Tabs } from '@maxtropy/components';
import { Tab } from 'rc-tabs/lib/interface';
import ReactEcharts from 'echarts-for-react';
import { cloneDeep } from 'lodash-es';
import { customFixed } from '@/utils/utils';
import dayjs from 'dayjs';

const ColorMap = {
  yellow: '#FFCB47',
  green: '#16DD8E',
  blue: '#2D8DFF',
};

const lineIcon = 'path://M0 0h16v4H0z';
const barIcon = 'path://M0 0h12v12H0z';

interface Props {
  data: V2PvOutlinePvDataStatisticsPostResponse | undefined;
  timeType: TimeType;
  powerTab: PowerTab;
  granularity: Granularity;
  setPowerTab: (powerTab: PowerTab) => void;
}

const ElecChart: FC<Props> = props => {
  const { data, timeType, granularity, powerTab, setPowerTab } = props;

  const tabItems: Tab[] = [
    {
      label: '发电量',
      key: String(PowerTab.GENERATION),
    },
    {
      label: '上网电量',
      key: String(PowerTab.ONLINE),
    },
    {
      label: '消纳电量',
      key: String(PowerTab.CONSUMPTION),
    },
  ];

  /*
    发电量+不勾选分时统计：显示总收益、并网点总发电量、逆变器总发电量；
    发电量+勾选分时统计：显示尖/峰/平/谷-并网点总发电量；
    上网电量：显示上网电量，上网收益，勾选分时统计时加上尖/峰/平/谷
    消纳电量：显示消纳电量，消纳收益，勾选分时统计时加上尖/峰/平/谷
   */
  const chartOption = useMemo(() => {
    const dataList = data?.pvStationDataValueStatisticsList ?? [];
    const baseOption = {
      grid: {
        left: '0',
        right: '0',
        bottom: '3%',
        containLabel: true,
      },
      legend: {
        right: 0,
        itemGap: 24,
        itemWidth: 16,
        itemHeight: 12,
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        data: [],
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderColor: 'transparent',
        textStyle: {
          color: 'rgba(255,255,255,0.65)',
        },
        formatter: (items: any[]) => {
          const { axisValueLabel } = items[0];
          let str: string = dayjs(axisValueLabel).format(granularity === Granularity.DAY ? 'YYYY-MM-DD' : 'YYYY-MM');
          items.forEach(item => {
            const { seriesName, data, marker, componentSubType } = item;
            const value = customFixed(data[1]);
            str += `<div style="display:flex;margin-top:4px;justify-content:space-between;gap:30px;">
                    <span>${marker}${seriesName}</span>
                    <span>${value}${componentSubType === 'line' ? '元' : 'kWh'}</span>
                  </div>`;
          });
          return str;
        },
      },
      xAxis: {
        type: 'time',
        axisLabel: {
          color: 'rgba(255,255,255,0.85)',
        },
      },
      yAxis: [
        {
          type: 'value',
          name: '电量(kWh)',
          position: 'left',
          splitLine: {
            lineStyle: {
              color: 'rgba(255,255,255,0.3)',
            },
          },
          axisLabel: {
            color: 'rgba(255,255,255,0.85)',
          },
          nameTextStyle: {
            color: 'rgba(255,255,255,0.85)',
            padding: [0, 0, 0, 60],
          },
        },
        {
          type: 'value',
          name: '收益(元)',
          position: 'right',
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: 'rgba(255,255,255,0.85)',
          },
          nameTextStyle: {
            color: 'rgba(255,255,255,0.85)',
            padding: [0, 60, 0, 0],
          },
        },
      ],
      series: [],
    };
    const option = cloneDeep(baseOption);
    if (powerTab === PowerTab.GENERATION) {
      if (timeType === TimeType.NONE) {
        (option.series as any).push(
          {
            name: '总收益',
            type: 'line',
            yAxisIndex: 1,
            itemStyle: {
              color: ColorMap.yellow,
            },
            symbol: 'circle',
            symbolSize: 2,
            smooth: true,
            data: dataList.map(item => [item.time, item.income]),
          },
          {
            name: '并网点总发电量',
            type: 'bar',
            itemStyle: {
              color: ColorMap.green,
            },
            barWidth: 8,
            data: dataList.map(item => [item.time, item.totalGridConnectedPower]),
          },
          {
            name: '逆变器总发电量',
            type: 'bar',
            itemStyle: {
              color: ColorMap.blue,
            },
            barWidth: 8,
            barGap: '50%',
            data: dataList.map(item => [item.time, item.totalInverterPower]),
          }
        );
        (option.legend.data as any[]).push(
          {
            name: '总收益',
            icon: lineIcon,
          },
          {
            name: '并网点总发电量',
            icon: barIcon,
          },
          {
            name: '逆变器总发电量',
            icon: barIcon,
          }
        );
      } else {
        option.yAxis = [option.yAxis[0]];
        (option.series as any).push({
          name: TimeTypeLabel[timeType] + '-并网点总发电量',
          type: 'bar',
          itemStyle: {
            color: ColorMap.green,
          },
          barWidth: 8,
          data: dataList.map(item => [item.time, item.totalGridConnectedPower]),
        });
        (option.legend.data as any[]).push({
          name: TimeTypeLabel[timeType] + '-并网点总发电量',
          icon: barIcon,
        });
      }
    } else {
      (option.series as any).push(
        {
          name:
            TimeTypeLabel[timeType] +
            (timeType !== TimeType.NONE ? '-' : '') +
            (powerTab === PowerTab.ONLINE ? '上网电量' : '消纳电量'),
          type: 'bar',
          itemStyle: {
            color: powerTab === PowerTab.ONLINE ? ColorMap.blue : ColorMap.green,
          },
          barWidth: 8,
          data: dataList.map(item => [
            item.time,
            powerTab === PowerTab.ONLINE ? item.chargeOnGrid : item.chargeConsumption,
          ]),
        },
        {
          name:
            TimeTypeLabel[timeType] +
            (timeType !== TimeType.NONE ? '-' : '') +
            (powerTab === PowerTab.ONLINE ? '上网收益' : '消纳收益'),
          type: 'line',
          yAxisIndex: 1,
          itemStyle: {
            color: ColorMap.yellow,
          },
          symbol: 'circle',
          symbolSize: 2,
          smooth: true,
          data: dataList.map(item => [item.time, item.income]),
        }
      );
      (option.legend.data as any[]).push(
        {
          name:
            TimeTypeLabel[timeType] +
            (timeType !== TimeType.NONE ? '-' : '') +
            (powerTab === PowerTab.ONLINE ? '上网电量' : '消纳电量'),
          icon: barIcon,
        },
        {
          name:
            TimeTypeLabel[timeType] +
            (timeType !== TimeType.NONE ? '-' : '') +
            (powerTab === PowerTab.ONLINE ? '上网收益' : '消纳收益'),
          icon: lineIcon,
          width: 16,
          height: 4,
        }
      );
    }

    return option;
  }, [data, timeType, powerTab]);

  return (
    <div>
      <Tabs
        items={tabItems}
        activeKey={String(powerTab)}
        onChange={val => {
          setPowerTab(Number(val) as PowerTab);
        }}
      ></Tabs>
      <ReactEcharts option={chartOption} notMerge></ReactEcharts>
    </div>
  );
};

export default ElecChart;
