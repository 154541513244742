import { InfoCircleOutlined } from '@ant-design/icons';
import { DatePicker, Form, Modal, message } from '@maxtropy/components';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useEffect } from 'react';

interface Iprops {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onFinish: (values: any) => void;
}

const { RangePicker } = DatePicker;

const ReCalcModal: FC<Iprops> = props => {
  const { visible, setVisible, onFinish } = props;
  const [form] = Form.useForm();

  // 默认一个月
  useEffect(() => {
    if (visible && form) {
      form.setFieldValue('timeShareCalcTimes', [
        dayjs(dayjs().subtract(1, 'month'), 'x'),
        dayjs(dayjs().subtract(1, 'day'), 'x'),
      ]);
    }
  }, [visible, form]);

  // 分时重计算禁用日期
  const onChangeRangePicker = (range: any) => {
    if (range) {
      const [beginDate, endDate] = range;
      const diff = dayjs(endDate).diff(dayjs(beginDate), 'month');
      const mostDays = 12;
      if (diff >= mostDays) {
        form.setFieldValue('timeShareCalcTimes', [
          dayjs(dayjs().subtract(1, 'month'), 'x'),
          dayjs(dayjs().subtract(1, 'day'), 'x'),
        ]);
        return message.warning(`最大上限为12个月`);
      }
    }
  };

  return (
    <Modal
      open={visible}
      title={
        <>
          <div style={{ margin: '10px 0' }}>分时重计算</div>
          <p style={{ margin: 0, fontSize: '14px', color: 'rgba(255, 255, 255, 0.65)' }}>
            <InfoCircleOutlined style={{ marginRight: 6 }} />
            大约需要5-30分钟时间重新计算尖峰平谷电量或者线损数据。
          </p>
        </>
      }
      maskClosable={false}
      onCancel={() => setVisible(false)}
      onOk={() => {
        form.submit();
      }}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name="timeShareCalcTimes"
          initialValue={[dayjs(dayjs().subtract(1, 'month'), 'x'), dayjs(dayjs().subtract(1, 'day'), 'x')]}
          label="选择时间"
          rules={[{ required: true, message: '请选择时间' }]}
        >
          <RangePicker
            disabledDate={(current: Dayjs) => {
              return current && current >= dayjs().endOf('day');
            }}
            onChange={onChangeRangePicker}
            allowClear={false}
            getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReCalcModal;
