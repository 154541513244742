export interface alarmInverterDeviceDtoList {
  deviceId?: string;
  /**
   * 设备编号
   */
  code?: string;
  /**
   * 设备名称
   */
  name?: string;
  /**
   * 片区
   */
  pvAreaName?: number;
  /**
   * 离散率
   */
  rate?: number;
}

// 0 已读 1 未读
export enum ReadStateEnum {
  READ = 0,
  UNREAD = 1,
}
