import { FC } from 'react';
import styles from './index.module.scss';
import { AlertFilled } from '@ant-design/icons';
import { Space } from 'antd';
import classNames from 'classnames';
import { AlarmLevelNumEnum } from '@/api/bsaPVOperationDashBoard';
import { formateValuesFixTwo, formateValuesFixTwoPerent } from './utils';
import { isNil } from 'lodash-es';
import { PvStationItem } from '@/pages/PVOperationDashBoard';
import { Button, Tooltip } from '@maxtropy/components';

export interface PvOperationCardProps {
  item: PvStationItem;
  isChannel?: boolean;
}

const PvOperationCard: FC<PvOperationCardProps> = props => {
  const { item, isChannel } = props;

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.cardHeader}>
        <Space
          size={2}
          className={classNames({
            [styles.alert]:
              !isNil(item.levelNum?.find(i => i.level === AlarmLevelNumEnum.MAX)?.num) &&
              !isNil(item.levelNum?.find(i => i.level === AlarmLevelNumEnum.HIGH)?.num) &&
              item.levelNum?.find(i => i.level === AlarmLevelNumEnum.MAX)?.num! +
                item.levelNum?.find(i => i.level === AlarmLevelNumEnum.HIGH)?.num! >
                0
                ? true
                : false,
          })}
        >
          <div className={styles.icon}>
            <AlertFilled />
          </div>
          <span className={styles.title}>
            <span
              className={styles.sTitle}
              style={{
                color:
                  !isNil(item.levelNum?.find(i => i.level === AlarmLevelNumEnum.MAX)?.num) &&
                  !isNil(item.levelNum?.find(i => i.level === AlarmLevelNumEnum.HIGH)?.num) &&
                  item.levelNum?.find(i => i.level === AlarmLevelNumEnum.MAX)?.num! +
                    item.levelNum?.find(i => i.level === AlarmLevelNumEnum.HIGH)?.num! >
                    0
                    ? 'red'
                    : 'unset',
              }}
            >
              {item.name}
            </span>
          </span>
        </Space>
      </div>
      <div className={styles.cardContentWrapper}>
        <div className={styles.content}>
          <Space direction="vertical" align="center">
            <span className={styles.value}>{!isNil(item.realTimePower) ? item.realTimePower.toFixed(2) : '--'}kW</span>
            <span className={styles.desc}>实时功率</span>
            <span className={styles.value}>
              {!isNil(item.irradiationIntensity) ? item.irradiationIntensity.toFixed(2) : '--'}W/㎡
            </span>
            <span className={styles.desc}>辐照强度</span>
          </Space>
        </div>
        <div className={styles.content}>
          <Space direction="vertical" align="center">
            <span className={styles.value}>{formateValuesFixTwo(item.charge)}kWh</span>
            <span className={styles.desc}>今日发电量</span>
            <span className={styles.value}>
              {item.showPowerGenerationEfficiency ? (
                !isNil(item.powerGenerationEfficiency) ? (
                  formateValuesFixTwoPerent(item.powerGenerationEfficiency) + '%'
                ) : (
                  '--'
                )
              ) : (
                <span style={{ color: '#ffffff80' }}>缺少辐照数据</span>
              )}
            </span>
            <span className={styles.desc}>实时发电效率</span>
          </Space>
        </div>
      </div>
      <div className={styles.valueContainer2}>
        {isChannel && (
          <div className={`${styles.item} ${styles.tenantItem}`}>
            <div className={styles.name}>租户信息:</div>
            <div className={styles.line}></div>
            <div className={styles.info}>
              <Tooltip placement="top" title={item.tenantName}>
                {item.tenantName}
              </Tooltip>
            </div>
          </div>
        )}
        <div className={styles.item}>
          <div className={styles.name}>装机容量:</div>
          <div className={styles.line}></div>
          <div>{formateValuesFixTwo(item.installedCapacity)}MW</div>
        </div>
        {!isChannel && (
          <div className={styles.item}>
            <span>报警信息:</span>
            <div className={styles.line}></div>
            <div>
              <Tooltip placement="top" title="最高级">
                <span style={{ color: '#FF4D4F' }}>
                  {item?.levelNum ? item?.levelNum.find(i => i.level === AlarmLevelNumEnum.MAX)?.num ?? '0' : '0'}
                </span>
              </Tooltip>
              <span>/</span>
              <Tooltip placement="top" title="高级">
                <span style={{ color: '#D89614' }}>
                  {item?.levelNum ? item?.levelNum.find(i => i.level === AlarmLevelNumEnum.HIGH)?.num ?? '0' : '0'}
                </span>
              </Tooltip>
              <span>/</span>
              <Tooltip placement="top" title="中级">
                <span style={{ color: '#157DDC' }}>
                  {item?.levelNum ? item?.levelNum.find(i => i.level === AlarmLevelNumEnum.MIDDLE)?.num ?? '0' : '0'}
                </span>
              </Tooltip>
              <span>/</span>
              <Tooltip placement="top" title="低级">
                <span style={{ color: '#4AA91A' }}>
                  {item?.levelNum ? item?.levelNum.find(i => i.level === AlarmLevelNumEnum.LOW)?.num ?? '0' : '0'}
                </span>
              </Tooltip>
              <span>/</span>
              <Tooltip placement="top" title="最低级">
                <span style={{ color: '#A7B809' }}>
                  {item?.levelNum ? item?.levelNum.find(i => i.level === AlarmLevelNumEnum.WARNING)?.num ?? '0' : '0'}
                </span>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
      {!isChannel && (
        <div className={styles.BtnLinkFooter}>
          <Space>
            <Button
              style={{ padding: 0, fontSize: 12 }}
              type="link"
              onClick={() => window.open(`${window.PVANALYSIS}/actual-time-board-v2?stationId=${item.id}`, '_blank')}
            >
              逆变器监控
            </Button>
            <Button
              style={{ padding: 0, fontSize: 12 }}
              type="link"
              onClick={() => window.open(`/assets/alarm/overview?assetCode=${item.assetCode}`, '_blank')}
            >
              报警记录
            </Button>
            <Button
              style={{ padding: 0, fontSize: 12 }}
              type="link"
              onClick={() =>
                window.open(`${window.SEVENNINENINESEVEN}/operate-overview?stationId=${item.id}`, '_blank')
              }
            >
              站点详情
            </Button>
            <Button
              style={{ padding: 0, fontSize: 12 }}
              type="link"
              onClick={() =>
                window.open(`${window.SEVENNINENINESEVEN}/station-analysis?stationId=${item.id}`, '_blank')
              }
            >
              运行数据
            </Button>
          </Space>
        </div>
      )}
    </div>
  );
};

export default PvOperationCard;
